<template>
  <v-container class="ak-page-container">
    <PageTitle title="Hall Of Fame " />

    <p class="hof-awards-counter">
      {{ collectedAwards }}/{{ awardsTotalNumber }}
    </p>

    <v-row no-gutters>
      <v-col cols="12">
        <div class="hof-background">
          <v-row no-gutters>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="ANA_BABY"
                v-bind:data="findAward('ANA_BABY')"
                :award-details="findAwardDetails('ANA_BABY')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="ANA_FAN"
                v-bind:data="findAward('ANA_FAN')"
                :award-details="findAwardDetails('ANA_FAN')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="ANA_STAR"
                v-bind:data="findAward('ANA_STAR')"
                :award-details="findAwardDetails('ANA_STAR')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="NUMBER_ONE"
                v-bind:data="findAward('NUMBER_ONE')"
                :award-details="findAwardDetails('NUMBER_ONE')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="SCHLAUMEIER"
                v-bind:data="findAward('SCHLAUMEIER')"
                :award-details="findAwardDetails('SCHLAUMEIER')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="KOLUMBUS"
                v-bind:data="findAward('KOLUMBUS')"
                :award-details="findAwardDetails('KOLUMBUS')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="DRUNKEN_SAILOR"
                v-bind:data="findAward('DRUNKEN_SAILOR')"
                :award-details="findAwardDetails('DRUNKEN_SAILOR')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="BLINDES_HUHN"
                v-bind:data="findAward('BLINDES_HUHN')"
                :award-details="findAwardDetails('BLINDES_HUHN')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="WHISKY"
                v-bind:data="findAward('WHISKY')"
                :award-details="findAwardDetails('WHISKY')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="TEFLON"
                v-bind:data="findAward('TEFLON')"
                :award-details="findAwardDetails('TEFLON')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="FLEISSIGE_BIENE"
                v-bind:data="findAward('FLEISSIGE_BIENE')"
                :award-details="findAwardDetails('FLEISSIGE_BIENE')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
            <v-col md="3" class="hof-center-grid">
              <award
                award-key="ANA_BOND"
                v-bind:data="findAward('ANA_BOND')"
                :award-details="findAwardDetails('ANA_BOND')"
                @acknowledgeAward="acknowledgeAward"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from '@/components/common/PageTitle';
import Award from '@/components/common/Award';

export default {
  name: 'HallOfFame',
  components: { Award, PageTitle },

  data() {
    return {
      awardsTotalNumber: 12,
      awards: [],
      dict: {
        ANA_BABY: {
          name: 'AnaBaby',
          description: 'Gratulation! Du hast deine erste Challenge gespielt.',
          background: 'hof-background-ana-baby',
          emoji: '\uD83D\uDC76',
        },
        ANA_FAN: {
          name: 'AnaFan',
          description: 'Gratulation! Du hast deine fünfte Challenge gespielt.',
          background: 'hof-background-ana-fan',
          emoji: '\u2764\ufe0f',
        },
        ANA_STAR: {
          name: 'AnaStar',
          description: 'Gratulation! Du hast bereits 20 Challenges gespielt.',
          background: 'hof-background-ana-star',
          emoji: '\uD83C\uDF1F',
        },
        NUMBER_ONE: {
          name: 'Number One',
          description: 'Spitzenleistung! Du hast Platz 1 im Ranking erreicht.',
          background: 'hof-background-number-one',
          emoji: '\uD83E\uDD47',
        },
        SCHLAUMEIER: {
          name: 'Schlaumeier',
          description: 'Respekt! Fünf Challenges ohne Fehler - weiter so!',
          background: 'hof-background-schlaumeier',
          emoji: '\uD83D\uDCA1',
        },
        KOLUMBUS: {
          name: 'Kolumbus',
          description:
            'Wie Kolumbus - Du hast eine neue Challenge als allererste Person gelöst.',
          background: 'hof-background-kolumbus',
          emoji: '⛵\u200D‍️',
        },
        DRUNKEN_SAILOR: {
          name: 'Drunken Sailor',
          description:
            'Das waren wohl einige Drinks zu viel gestern Abend - Du hast ALLE Fragen einer Challenge falsch beantwortet.',
          background: 'hof-background-drunken-sailor',
          emoji: '\uD83E\uDD74',
        },
        BLINDES_HUHN: {
          name: 'Blindes Huhn',
          description:
            'Auch ein blindes Huhn findet ab und zu ein Korn - Du hast in dieser Challenge nur eine einzige Frage richtig beantwortet.',
          background: 'hof-background-blindes-huhn',
          emoji: '\uD83D\uDC13',
        },
        WHISKY: {
          name: 'Whisky',
          description:
            'Was lange reift wird endlich gut - Du hast die gleiche Challenge 8x in Folge gelöst.',
          background: 'hof-background-whisky',
          emoji: '\uD83E\uDD43',
        },
        TEFLON: {
          name: 'Teflon',
          description:
            'Wie in der Bratpfanne - Auch nach dem 20. Versuch blieb aus dieser Challenge nicht viel hängen...',
          background: 'hof-background-teflon',
          emoji: '\uD83C\uDF73',
        },
        FLEISSIGE_BIENE: {
          name: 'Fleissige Biene',
          description: 'Sensationell! Du hast bereits 100 Fragen beantwortet.',
          background: 'hof-background-fleissige-biene',
          emoji: '\uD83D\uDC1D',
        },
        ANA_BOND: {
          name: 'AnaBond 007',
          description:
            'Ein Auftritt fast wie im Agent:innen-Film - Du hast eine Speziallerneinheit gewonnen!',
          background: 'hof-background-ana-bond',
          emoji: '\uD83D\uDD75',
        },
      },
    };
  },

  created() {
    this.listAwards().then((awards) => (this.awards = awards));
  },

  methods: {
    async listAwards() {
      try {
        let res = await this.$axios.get(
          `/api/v1/user/awards`,
          this.axiosGetConfig
        );
        return res.data.data.map((d) => d.data);
      } catch (e) {
        debugger;
        return [];
      }
    },
    findAward(awardKey) {
      return this.groupedAwards[awardKey];
    },
    findAwardDetails(awardKey) {
      if (this.dict[awardKey] != null) {
        this.dict[awardKey].count = this.findAwardCount(awardKey);
        return this.dict[awardKey];
      }
    },
    findAwardCount(awardKey) {
      if (this.groupedAwards[awardKey]) {
        return this.groupedAwards[awardKey].length;
      }
      return 0;
    },
    acknowledgeAward(awardKey) {
      let unacknowledgedAwards = this.groupedAwards[awardKey].filter(
        (it) => !it.acknowledged
      );

      this.groupedAwards[awardKey].forEach((it) => (it.acknowledged = true));

      unacknowledgedAwards.forEach((elem) => {
        let body = {};
        body.id = elem.id;
        body.acknowledged = true;

        this.$axios
          .put(`/api/v1/user/awards/${elem.id}`, body, this.axiosPostPutConfig)
          .then((res) => {
            console.log('RESPONSE RECEIVED put award: ', res);
          })
          .catch((err) => {
            console.log('AXIOS ERROR put award: ', err);
          });
      });

      this.updateUserInfo();
      this.$forceUpdate();
    },
    updateUserInfo() {
      this.$store.dispatch('user/loadUserData');
    },
  },
  computed: {
    groupedAwards() {
      return this.awards.reduce((groups, item) => {
        const group = groups[item.name] || [];
        group.push(item);
        groups[item.name] = group;
        return groups;
      }, {});
    },
    collectedAwards() {
      return Object.keys(this.groupedAwards).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.hof-background {
  background: var(--v-secondary-base);
  border-radius: 10px;
  padding: 18px 11px !important;
  //max-height: 60vh;
  overflow: scroll;
  position: relative;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.hof-awards-counter {
  color: var(--v-secondary-base);
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: -60px;
  margin-bottom: 30px;
}

.hof-center-grid {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
